import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  async getListOrg() {
    try {
      const { data } = await axiosApiInstance.get('/OrganizationStructureEmployment/organization-structure-employment')
      return data
    } catch (err) {
      return err
    }
  },
  async getListOrgUser(payload) {
    try {
      const { data } = await axiosApiInstance.get('/OrganizationStructureEmployment/list-organization-structure-employment-worker', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async getListUserModal(payload) {
    try {
      const { data } = await axiosApiInstance.get('/OrganizationStructureEmployment/list-worker-not-register', { params: payload })
      return data
    } catch (err) {
      return err
    }
  },
  async addUserToOrg(payload) {
    try {
      const { data } = await axiosApiInstance.post('/OrganizationStructureEmployment/add-organization-structure-employment-worker', payload)
      return data
    } catch (err) {
      return err
    }
  },
  async removeUserFromOrg(payload) {
    try {
      const { data } = await axiosApiInstance.post('/OrganizationStructureEmployment/delete-organization-structure-employment-worker', payload)
      return data
    } catch (err) {
      return err
    }
  },
}
