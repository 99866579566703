<template>
  <div class="page-container-table">
    <vue-good-table
      ref="org-table"
      class="mh-60"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span class="text-nowrap">
          {{ props.column.label }}
        </span>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'action'">
          <span
            v-b-tooltip.hover.top="'Thêm người lao động'"
            class="ml-2"
            @click="$router.push({name: 'org-struct-user-employment',params: { id: props.row.id }})"
          >
            <feather-icon
              icon="PlusCircleIcon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import store from '../store'

export default {
  components: {
    VueGoodTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      columns: [
        {
          label: 'Tên cơ cấu tổ chức',
          field: 'name',
          sortable: false,
        },
        {
          label: 'Số người lao động thuộc cơ cấu tổ chức',
          field: 'amount',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      dataList: [],
    }
  },
  created() {
    this.getListOrg()
  },
  methods: {
    async getListOrg() {
      this.$showLoading()
      const { data } = await store.getListOrg()
      this.dataList = data
      this.$hideLoading()
    },
  },
}
</script>
