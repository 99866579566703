var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table"},[_c('vue-good-table',{ref:"org-table",staticClass:"mh-60",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: true,
    }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(props.column.label)+" ")])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Thêm người lao động'),expression:"'Thêm người lao động'",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",on:{"click":function($event){return _vm.$router.push({name: 'org-struct-user-employment',params: { id: props.row.id }})}}},[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"PlusCircleIcon","size":"18"}})],1)]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }